import React, { useState } from 'react'
import Layout from '../../components/layout'
import { Container, Row, Col, Modal, ModalHeader, ModalBody, } from 'reactstrap'
import styled from 'styled-components'
// import { graphql, StaticQuery } from 'gatsby'
// import Img from "gatsby-image"
import { FaLinkedin } from 'react-icons/fa';
// import Logo from '../../images/TriumphDay/logo.png'
// import Text from '../../images/TriumphDay/text.png'
import Video from '../../images/ceo/video_banner.jpg'
import Video1 from '../../images/ceo/video_banner1.jpg'
import Fast100 from '../../images/ceo/fast100.jpg';
import Banner1 from '../../images/TriumphDay/why_trident.jpg'
import Banner2 from '../../images/TriumphDay/team_building.jpg'
import Banner3 from '../../images/TriumphDay/triumph_day.jpg'
import YouTube from 'react-youtube';
import AudioPlayer from 'react-h5-audio-player';
import AudioImg from '../../images/audi-player.jpg'

import BlogForm from './Blog/BlogForm'


const WhyTrident = (props) => {
  const {
    // buttonLabel,
    className
  } = props;
  const youTubeOpts = {
    // height: '100%',
    // width: '100%',
    playerVars: {
      autoplay: 1,
      modestbranding: 0,
      mute: 1,
      loop: 1,
      // playlist: 'W1gxesz0b1g',
      autohide: 1
    }
  }

  const [modal, setModal] = useState(false);
  // const [nestedModal, setNestedModal] = useState(false);
  // const [closeAll, setCloseAll] = useState(false);
  const toggle = () => setModal(!modal);

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);


  return (
    <Layout path="/whytrident/">
      {/* <div className="banner_section">
            <Img
              Tag="section"
              fluid={imageData}
              className="startup_background"
            ></Img>
            <div className="triumph_banner">
              <div className="triumph_text_img">
                <img src={Text} width={500} alt="img" />
              </div>
              <h2 className="triumph_text1">TRIDENT: The    Winning Team</h2>
              <Row>
                <Col md="6" className="address_card">
                  <div className="triumph_address">
                    <h3> <strong>U.S.</strong><br />
                      San Ramon,CA<br />
                      Lewisville, TX<br />
                      Bridgewater,NJ</h3>
                  </div>
                </Col>
                <Col md="6" className="address_card">
                  <div className="triumph_address1">
                    <h3><strong>INDIA</strong><br />
                      Chennai<br />
                      Coimbatore<br />
                      Tirunelveli</h3>
                  </div>
                </Col>
              </Row>
            </div>
          </div> */}
      <section>
        {/* <Img fluid={banner1} /> */}
        <img src={Banner1} alt="img" />
        <div className="trident_section">
          <Container>
            <div className="text_center">
              <h3>Why Trident</h3>
            </div>
            <p>There’s more to Trident than resumes and profiles, RFPs and contracts. Work life balance is something we take very seriously here. While there’s hot competition for the cool increments and incentives, we are acutely aware that our biggest competition is within us. We have to fill faster than we did for the last position, reach farther than the last candidate, and work harder than for the last submission. Learning and having fun along the way. There’s always a seminar to learn from, a conference to network at, and another colleague to welcome. Not a place to stop and stare. </p>
            <div>

            </div>
          </Container>
        </div>
      </section>
      <section>
        {/* <Img fluid={banner2} /> */}
        <img src={Banner2} alt="img" />
        <div className="trident_section">
          <Container>
            <div className="text_center">
              <h3>Team Building</h3>
            </div>
            <p>Team work is more than just a term at Trident. Whether it is working to fill a position or cooperating to win a game, we know that our co-workers always have our back. We believe that, in our line of work slam dunk happens only with team work, transparency, and trust. Team Trident: and we mean it.</p>
            {/* <p>Team work is more than just a term at Trident. Whether it is working to fill a position or cooperating to win a game, we know that our co-workers always have our back. We believe that, in our line of work slum dunk happens only with team work, transparency, and trust. Team Trident: and we mean it.</p> */}
          </Container>
        </div>
      </section>
      <section>
        {/* <Img fluid={banner3} /> */}
        <img src={Banner3} alt="img" width="100%" />
        <div className="trident_section">
          <Container>
            <div className="text_center">
              <h3>Triumph Day</h3>
            </div>
            <p style={{ fontStyle: ' italic', }}>"Milestones. We are not always aware of them, but when we cross them, we are filled with a sense of accomplishment. Trident Consulting crossed a milestone at ten years and this special occasion was aptly named Triumph Day. On June 23rd, 2019 at the InterContinental Resort on the famed ECR, in Chennai, the team gathered to both wind down and fire up as they marched triumphantly past Milestone 10. A sumptuous lunch followed, complete with delicious desserts and a celebratory cake. The post lunch agenda was team building exercises conducted by Victory Insights. The activities revolved around the importance of resourcefulness, team spirit, and tools to tap one’s full potential. Trident’s Triumph Day is the first of the many milestones that the company is all set to conquer."</p>
            {/* <p>Milestones. We are not always aware of them, but when we cross them, we are filled with a sense of accomplishment. Trident Consulting crossed a milestone at ten years and this special occasion was aptly named Triumph Day.  On June 23rd, 2019 at the InterContinental Resort on the famed ECR, in Chennai, the team gathered to both wind down and fire up as they marched triumphantly past Milestone 10.
                 A sumptuous lunch followed, complete with delicious desserts and a celebratory cake. The post lunch agenda was team building exercises conducted by Victory Insights. The activities revolved around the importance of resourcefulness, team spirit, and tools to tap one’s full potential. 
                 Trident’s Triumph Day is the first of the many milestones that the company is all set to conquer. 
              </p> */}
          </Container>
        </div>
      </section>
      {/* <section className="refer_friend_section">
            <Container>
              <div className="text_center">
                <h3>Trident Consulting’s Triumph Day</h3>
              </div>
              <Row>
                <Col md='12'>
                  <div className="triumph_text">
                    <p>Milestones. We are not always aware of them, but when we cross them, we are filled with a sense of accomplishment. Trident Consulting crossed a milestone at ten years and this special occasion was aptly named Triumph Day.  On June 23rd at the InterContinental Resort on the famed ECR, in Chennai, the team gathered to both wind down and fire up as they marched triumphantly past Milestone 10.</p>
                    <p>A sumptuous lunch followed, complete with delicious desserts and a celebratory cake. The post lunch agenda was team building exercises conducted by Ms. Padmaja. The activities revolved around the importance of resourcefulness, team spirit, and tools to tap one’s full potential. </p>
                    <p>Trident’s Triumph Day is the first of the many milestones that the company is all set to conquer. For now it’s back to business.  </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6" className="collage_img">
                  <Img fluid={banner1} className="collage_img1" />
                  <Img fluid={imageData3} className="collage_img" />
                  <Img fluid={imageData5} className="collage_img1" />
                  <Img fluid={imageData11} className="collage_img1" />
                  <Img fluid={imageData9} className="collage_img1" />
                  <Img fluid={imageData16} className="collage_img1" />
                  <Img fluid={imageData14} className="collage_img1" />
                  <Img fluid={imageData13} className="collage_img1" />
                  <Img fluid={imageData18} className="collage_img1" />


                </Col>
                <Col md="6" className="collage_img">
                  <Img fluid={imageData1} className="collage_img" />
                  <Img fluid={imageData8} className="collage_img1" />
                  <Img fluid={imageData7} className="collage_img1" />
                  <Img fluid={imageData4} className="collage_img" />
                  <Img fluid={imageData10} className="collage_img1" />
                  <Img fluid={imageData12} className="collage_img1" />
                  <Img fluid={imageData15} className="collage_img1" />
                  <Img fluid={imageData17} className="collage_img1" />


                </Col>
              </Row>
            </Container>
          </section> */}
      <section>
        <div className="video_data_section">
          <Container>
            <Row>
              <Col md="6">
                <div className="video_data">
                  <div>
                    <img src={Video} alt="img" />
                  </div>
                  <p>Trident enters Inc.'s 2020 List of 5000 Fastest Growing Private Companies in America</p>
                  <p>Ranked 3720,<a target="blank" href="https://www.inc.com/profile/trident-consulting?utm_source=sendinblue&utm_campaign=Trident_PostInc_Award_Email&utm_medium=email">Trident enters this elite list </a> with a 98% growth rate in the past 3 years. Equally important though is the gritty 15% growth recorded in this tough Quarter.</p>
                  <h5> <a target="blank" href="https://www.inc.com/inc5000/2020?utm_source=sendinblue&utm_campaign=Trident_PostInc_Award_Email&utm_medium=email">Click here for the full list of Inc.'s 2020 awardees...</a></h5>
                </div>
              </Col>
              <Col md="6">
                <div className="video_data1">
                  <div>
                    <img src={Video1} alt="img" />
                  </div>
                  <p>"Look within for strength, look outside for opportunity, improve, impress, and grow together with your client"</p>
                  <p>Click <a href="#" onClick={toggle}> here </a> for a 2-minute excerpt from an interview where Trident CEO Shabana Siraj talks about staffing done during the pandemic. </p>
                  <h5><a href="#" onClick={toggle}>Click here for the full interview with Joya Dass...</a> </h5>
                </div>
                <Modal isOpen={modal} toggle={toggle} className="modal-dialog_video">
                  <ModalHeader toggle={toggle}></ModalHeader>
                  <ModalBody>
                    <YouTube
                      opts={youTubeOpts}
                      videoId="W1gxesz0b1g"
                      onPlay={true}
                    />
                  </ModalBody>
                </Modal>
              </Col>
              <Col sm="6">
                <div className="video_data1">
                  <div>
                    <img src={Fast100} alt="img" />
                  </div>
                  <h5><a href="https://www.bizjournals.com/sanfrancisco/news/2020/10/29/100-fastest-growing-companies-bay-area-2020.html#g/473052/57" target="blank" >San Francisco Bay Area's fastest-growing private companies in 2020 - San Francisco Business Times</a></h5>
                  <p>Want to know which Bay Area companies have been growing exponentially over the past few years? Check out our Fast 100 List.</p>
                  <a href="https://www.linkedin.com/feed/" target="blank">
                    <FaLinkedin size={20} />
                  </a>
                  <br />
                  <br />
                </div>
              </Col>
              <Col md="6">
                <div className="audio_player_card">
                  <div className="data">
                    <img src={AudioImg} width={"77%"} alt="audio" />
                    <p>BlueBegum</p>
                    <h4>High on Life - Meet Shabana Siraj</h4>
                  </div>
                  <AudioPlayer preload="auto" src={"https://episodes.buzzsprout.com/6b9quz1ooaujz66a42692ilfbii8?"} showJumpControls={false} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <section className="footer_section">
        <div className="trident_sectionn">
          <Container>
            <Row>
              <Col md="12">
                <h1>Find out what Trident can do for you.</h1>
                <div className="footer_button">
                  <a href onClick={toggle1}>Contact Us</a>
                </div>
                <Modal isOpen={modal1} toggle={toggle1} className={className} className="form_modal">
                  <ModalBody >
                    <BlogForm title="Find out what Trident can do for you." close={()=> toggle()}/>
                  </ModalBody>

                </Modal>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      {/* <BlogForm refItem={() => { }} title="Find out what Trident can do for you." redirectTitle="Why Trident" pathname={props.location.pathname} /> */}

    </Layout>
  )
}
const StyledBackgroundSection = styled(WhyTrident)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  margin: 0;
  background-color: inherit !important;
  
  `

export default StyledBackgroundSection
