import React, { useState } from "react";
import { Col, Container, Row, Button, Form, FormGroup, Label, Input, } from 'reactstrap';
import axios from "axios";


const BlogForm = (props) => {

    const [text, setText] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [company, setCompany] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false)
    const [isFirstNameEmpty, setisFirstNameEmpty] = useState(false)
    const [isLastNameEmpty, setisLastNameEmpty] = useState(false)
    // const [isValid, setisValid] = useState(false)
    const [isEnterPressed, setisEnterPressed] = useState(false)
    // const [handleSubmitClicked, sethandleSubmitClcicked] = useState(false)
    // const [isLastNameEmpty, setLastName] = useState(false)

    const handleSubmit = async event => {
        event.preventDefault();




        if (firstName == "") {
            setisFirstNameEmpty(true)
        } else {
            setisFirstNameEmpty(false)
        }
        if (lastName == "") {
            setisLastNameEmpty(true)
        } else {
            setisLastNameEmpty(false)
        }

        if (email === '' || !isEmailValid || firstName === '' || lastName === '') {
            return;
        }

        await axios.post('/api/create-contact', { firstName, lastName, email, phone, company, text });

        setText('');
        props.close();
        //navigate(`/Company/Blog/ThankYou?redirect=${props.pathname}&redirectTitle=${props.redirectTitle}`);
    }
    const EmailValidation = (value) => {
        setEmail(value)
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(String(value).toLowerCase())) {
            setIsEmailValid(true)
            console.log(`valid email:${value}`)
        } else {
            setIsEmailValid(false)
            console.log(` not a valid email:${value}`)
        }
    }
    const FirstNameValidation = (value) => {
        setFirstName(value)
        if (value == "") {
            console.log('Fild  empty')
            setisFirstNameEmpty(true)

        } else {
            setFirstName(value)
            setisFirstNameEmpty(false)
        }
    }
    const LastNameValidation = (value) => {
        setLastName(value)
        if (value == "") {
            console.log('Fild  empty')
            setisLastNameEmpty(true)

        } else {
            setLastName(value)
            setisLastNameEmpty(false)
        }
    }
    const handleKeyPress = (target) => {
        if (target.event == 'enter') {
            setisEnterPressed(true)
        }
    }


    return (
        <section className="blog_form_section" ref={props.refItem} >
            <Container>
                <Row style={{ marginBottom: 50 }}>
                    <Col md="12">
                        <div className="blog_form_heading">
                            <h1 className="text_center">{props.title}</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" md={{ size: 6, offset: 3 }}>
                        <Form  >
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>First Name *</Label>
                                        <Input type="text" placeholder="First Name" valid={!isFirstNameEmpty && isEnterPressed ? true : false} invalid={(isFirstNameEmpty && firstName == "") ? true : false} value={firstName} onChange={event => FirstNameValidation(event.target.value)} />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Last Name *</Label>
                                        <Input type="text" placeholder="Last Name" valid={!isLastNameEmpty && isEnterPressed ? true : false} invalid={(isLastNameEmpty && lastName == "") ? true : false} value={lastName} onChange={event => LastNameValidation(event.target.value)} />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Email *</Label>
                                        <Input valid={isEmailValid ? true : false} invalid={(!isEmailValid && email != "") ? true : false} type="email" name="email" placeholder="Email Address" value={email} onChange={event => EmailValidation(event.target.value)} />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label>Phone *</Label>
                                        <Input  type="number" name="phone" placeholder="Phone Number" value={phone} onChange={event => setPhone(event.target.value)}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <FormGroup>
                                <Label>Company *</Label>
                                <Input type="text" placeholder="Company" name="company" value={company} onChange={event => setCompany(event.target.value)}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="exampleText">Comments (Optional)</Label>
                                <Input placeholder="Comments..." type="textarea" value={text} onChange={event => setText(event.target.value)} />
                            </FormGroup>
                            <div className="blog_form_button">
                                <Button onClick={handleSubmit} >SUBMIT</Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </section>

    );
}

export default BlogForm;